$vmap-label-bg: #727272;
$vmap-label-font-family: "Poppins-Medium";
$vmap-label-letter-spacing: 0.8px;
$vmap-zoom-ctrl-bg: #515253;


body {

   .jvectormap-legend-title {
      font-size: 12px;
    }
    
    .jvectormap-legend .jvectormap-legend-tick-sample {
      height: 26px;
    }
    
    .jvectormap-legend-icons {
      background: white;
      border: black 1px solid;
    }
    
    .jvectormap-legend-icons {
      color: black;
    }

  .jvectormap-label {
   position: absolute;
   display: none;
   border: solid 1px $vmap-label-bg;
   border-radius: 2px;
   background: $vmap-label-bg;
   color: #FFFFFF;
   padding: 3px 6px;
   opacity: 0.9;
   z-index: 1100;
   font-family: $vmap-label-font-family;
   letter-spacing: $vmap-label-letter-spacing;
   font-size: 10px;
  }

.jvectormap-zoomin, .jvectormap-zoomout {
   position: absolute;
   left: 10px;
   width: 22px;
   height: 22px;
   border-radius: 2px;
   background: $vmap-zoom-ctrl-bg;
   padding: 5px;
   color: white;
   cursor: pointer;
   line-height: 10px;
   text-align: center;
}

.jvectormap-zoomin {
   top: 10px;
}
}
