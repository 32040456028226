$theme-primary-blue:#047BBC;
$theme-primary-grey:#727272;

$theme-primary-red:#ED1556;
$theme-input-placeholder:#B9B9B9;
$theme-white:#FFFFFF;
$theme-table-odd-row-color: #F1F1F1;
$row-height:30px;
$theme-disabled-color:#E2E2E2;
$theme-primary-orange-red:#E0532F;

$border-color: #E2E2E2;
$border-light-blue:#82BDDE; 
$border-light-gray:#B9B9B9; 
$theme-primary-light-skyblue:#CCE4F2;
$theme-grey44:#707070;
$theme-forest-green : #2CA01C;
// font 

$font-7:7px;
$font-8:8px;
$font-9:9px;
$font-10:10px;
$font-11:11px;
$font-12:12px;
$font-13:13px;
$font-14:14px;
$font-15:15px;
$font-16:16px;
$font-18:18px;
$font-19:19px;
$font-17:17px;
$font-20:20px;
$font-25:25px;

// border-radius

$theme-border-radius-8px:8px;
$theme-border-radius-7px:7px;
$theme-border-radius-10px:10px;
